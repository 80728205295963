.fotoFundoLogin {
    width: 20%;
    height: 100vh;
    background: url('./../../assets/images/cataratas.jpg') 25% 40%;
    background-size: cover;
}

@media only screen and (max-width: 576px) {
    .fotoFundoLogin {
        display: none;
    }
}

.infinite-scroll-component {
    scrollbar-color: rgba(0, 0, 0, 0.2) #868686;
    scrollbar-face-color: rgba(0, 0, 0, 0.4);
}

.infinite-scroll-component::-webkit-scrollbar {
    width: 7px !important;
}

.infinite-scroll-component::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.6);
}